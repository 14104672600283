<template>
  <div class="text-center">
    <h1
      class="
       text-4xl
       tracking-right
       font-extrabold
       text-gray-900
       sm:text-5xl
       md:text-6xl
      "
    >
      <span class="block">Success,</span>
      <span class="block text-mygrad">they're yours to keep!</span>
    </h1>
    <p
      class="
        mt-3
        max-w-md
        mx-auto
        text-base
        text-gray-500
        sm:text-lg
        md:mt-5
        md:text-xl
        md:max-w-3xl 
      "
    >
      Thank you for purchasing your special moment!
    </p>
    <p
      class="
        mt-3
        max-w-md
        mx-auto
        text-base
        text-gray-900
        text-xl
        sm:text-xl
        md:mt-5 
        md:text-xl 
        md:max-w-3xl 
      "
    >
      Physical copies can be purchased at the stand using this code: <span><b>{{ nanoID }}</b></span>
    </p>
    <p
      class="
        mt-3
        max-w-md
        mx-auto
        text-base
        text-gray-500
        sm:text-lg
        md:mt-5 
        md:text-xl 
        md:max-w-3xl 
      "
    >
      Digital photos will be sent to {{ email }} for you to download
    </p>
  </div>
</template>

<script>
export default {
  name: 'SnapshotSuccessMessage',
  props: {
      email: String,
      nanoID: String,
  }
};
</script>