<template>
  <div class="image-carousel-container">
    <div class="text-center pt-2">
      <button class="back-btn" @click="$emit('back-to-grid')">
        Back to Grid
      </button>
    </div>
    <vue-slick-carousel
      ref="largeCarousel"
      v-bind="settings"
      @beforeChange="syncSliders"
      @afterChange="setCurrentIndex"
    >
      <template #prevArrow>
        <div class="custom-arrow">
          <img :src="require('@/assets/arrow_left.svg')" />
        </div>
      </template>

      <div
        v-for="(image, index) in images"
        :key="image.id"
        class="preview"
        :class="{ selected: isSelected(image) }"
        @click="onImageClick(index)"
      >
        <img :src="image.src" :alt="image.alt" />
        <!-- NEW: Enlarge button on top of the image -->
        <button class="enlarge-btn" @click.stop="openFullscreen(index)">
          <svg width="16" height="16" viewBox="0 0 16 16">
            <path
              d="M1.5 1.5v4h1v-3h3v-1h-4zm13 0h-4v1h3v3h1v-4zm-13 13v-4h-1v4h4v-1h-3zm13-4v4h-4v-1h3v-3h1z"
              fill="white"
              stroke="white"
              stroke-width="1.5"
            />
          </svg>
        </button>
      </div>

      <template #nextArrow>
        <div class="custom-arrow">
          <img :src="require('@/assets/arrow_right.svg')" />
        </div>
      </template>
    </vue-slick-carousel>

    <button class="btn" type="button" @click="addCurrentImageToCart">
      Add to cart
    </button>
    <div class="text-center pt-2">
      <button class="view-cart-btn" @click="$emit('view-cart')">
        View Cart
      </button>
    </div>
    <div
      v-if="fullscreenVisible"
      class="fullscreen-overlay"
      @click.self="closeFullscreen"
    >
      <!-- Close Button -->
      <button class="close-modal-btn" @click="closeFullscreen">
        <svg width="16" height="16" viewBox="0 0 16 16">
          <path
            d="M3.5 3.5l9 9M12.5 3.5l-9 9"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </button>

      <!-- Show the currently selected image at large scale -->
      <img
        :src="images[fullscreenIndex].src"
        :alt="images[fullscreenIndex].alt"
        class="fullscreen-image"
      />
    </div>
  </div>
</template>
  
  <script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "PreviewCarousel",
  components: {
    VueSlickCarousel,
  },
  props: {
    images: { type: Array, default: () => [] },
    selectedImages: { type: Array, default: () => [] },
    settings: { type: Object, default: () => ({}) },
    initialSlide: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentIndex: 0,
      fullscreenVisible: false, // Controls if the enlarge modal is open
      fullscreenIndex: 0,
    };
  },
  mounted() {
    this.currentIndex = this.initialSlide;
    this.$refs.largeCarousel.goTo(this.initialSlide);
  },
  methods: {
    // Enlarge Modal Methods
    openFullscreen(index) {
      this.fullscreenIndex = index;
      this.fullscreenVisible = true;
    },
    closeFullscreen() {
      this.fullscreenVisible = false;
    },
    error(x) {
      console.log(x);
    },
    syncSliders(currentPos, nextPos) {
      // optional
      console.log(
        "Carousel is about to change from",
        currentPos,
        "to",
        nextPos
      );
    },
    setCurrentIndex(index) {
      this.currentIndex = index;
    },
    onImageClick(index) {
      this.setCurrentIndex(index);
    },
    addCurrentImageToCart() {
      // Emit an event with the currentIndex
      this.$emit("add-to-cart", this.currentIndex);
    },
    isSelected(image) {
      return this.selectedImages.includes(image);
    },
  },
};
</script>
  
  <style scoped>
/* All carousel-specific styles here. */
/* Style the button and place it in the middle of the container/image */
.image-carousel-container {
  max-width: 85vw;
  width: 100%;
  margin: auto;
}
.slick-prev:before {
  content: "";
}
.slick-next:before {
  content: "";
}

.preview-container {
  text-align: center;
  margin-bottom: 20px;
}

.preview-image {
  height: auto;
  max-height: 80vh;
  border: 3px solid #ddd;
  border-radius: 8px;
}

.thumbnail img {
  max-height: 20vh;
  padding: 5px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.1s ease;
}

.thumbnail img:hover,
.thumbnail img:focus {
  opacity: 1;
}

.preview {
  max-width: 90vw;
}
.preview img {
  max-height: 70vh;
  margin-left: auto;
  margin-right: auto;
  display: block;

  padding: 5px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.1s ease;
}

.preview img:hover,
.preview img:focus {
  opacity: 1;
}

.selected {
  border: 5px solid #08697b;
}
.selected img {
  padding: 0;
}
.image-carousel-container .btn {
  position: relative;
  /*top: 50%;*/
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #08697b;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.image-carousel-container .btn:hover {
  background-color: black;
}

.image-carousel-container .back-btn {
  background-color: #08697b;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin: auto;
}

.image-carousel-container .back-btn:hover {
  background-color: black;
}

.image-carousel-container .view-cart-btn {
  background-color: #08697b;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin: auto;
}

.image-carousel-container .view-cart-btn:hover {
  background-color: black;
}

/* Fullscreen Modal Overlay */
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreen-image {
  max-width: 95%;
  max-height: 95%;
  object-fit: contain;
}

/* Close Button on Modal */
.close-modal-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #08697b;
  color: #fff;
  font-size: 14px;
  padding: 6px 6px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
}
/* ENLARGE BUTTON on top of each image */
.enlarge-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #08697b;
  color: #fff;
  font-size: 14px;
  padding: 6px 6px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
}

/* Add position relative to the preview container */
.preview {
  position: relative;
  max-width: 90vw;
}
.enlarge-btn:hover {
  background-color: #333;
}
</style>
