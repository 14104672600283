<template>
    <div class="md:w-1/2 sm:w-full mx-auto">
      <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
        <div class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6" v-if="this.errorMessage == null">
          <div class="text-center">
            <h1
              class="
                text-2xl
                tracking-tight
                font-extrabold
                text-gray-900
                sm:text-3xl
                md:text-4xl
              "
            >
              <span class="block" 
                >Your images are ready!</span
              ><br/>
              <span class="block text-mygrad"
                >Click the button below to download the images, this can take a while...!</span
              >
            </h1>
          </div>
        </div>
        <div class="mt-8 mx-auto py-3 max-w-7xl w-full" v-if="this.errorMessage == null">
          <div class="text-center w-full h-auto">
            <button
              @click="submit"
              type="submit"
              class="
                inline-flex
                items-center
                px-4
                py-2
                border border-gray-300
                shadow-sm
                text-lg
                font-medium
                rounded-md
                text-gray-700
                bg-white
                hover:bg-gray-50
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
              "
            >
              Download!
            </button>
          </div>
        </div>
        <div class="mt-8 mx-auto py-3 max-w-7xl w-full" v-else>
          <div class="text-center w-full h-auto">
            <h2
              class="
                text-2xl
                tracking-tight
                font-extrabold
                text-gray-900
                sm:text-3xl
                md:text-4xl
              "
            >
              <span class="block"
                >{{ errorMessage }}</span
              >
            </h2> 
          </div>
        </div>
         
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    name: "Download",
    props: {  },
    data : function() {
      return {
        errorMessage: null
      }
    },
    methods: {
      submit() {

        axios
        .post(
          `${process.env.VUE_APP_SNAPSHOT_API_BASE}/download-digitals`,
          {
            order_nano_id: this.$route.params.nanoId,
          }
        )
        .then((r) => {
          this.errorMessage = "Your download should start shortly - your browser will handle the rest!"
          window.location.href = r.data.url;
        })
        .catch((e) => {
           this.errorMessage = e.response.data.message;
        })
      },
    },
  };
  </script>