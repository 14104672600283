<template>
  <div class="grid grid-cols-4 md:grid-cols-5 gap-4 justify-items-center">
    <!-- Loop through images -->
    <div 
      v-for="(image, index) in images" 
      :key="image.id" 
      @click="selectImage(index)" 
      class="flex justify-center items-center cursor-pointer hover:opacity-80"
    >
      <!-- Container for the image -->
      <div
        class="w-full overflow-hidden flex justify-center items-center"
        :class="{ 'border-4 border-[#08697b]': selectedImageIndex === index }"
      >
        <img 
          :src="image.src" 
          class="object-cover w-full h-full"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewGrid",
  props: { 
    images: { type: Array, default: () => [] },
    selectedImages: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectedImageIndex: null 
    }
  },
  methods: {
    selectImage(index) {
      this.selectedImageIndex = index;
      this.$emit('select-image', index);
    }
  }
};
</script>

<style>
.selected {
  border: 5px solid #08697b;
}
.selected img {
  padding: 0;
}
</style>