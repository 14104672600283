<template>
  <div class="lg:w-3/4 md:w-5/6 sm:w-full mx-auto">
    <div class="flex flex-col items-center space-y-4">
      <div class="w-full px-4 sm:px-6 text-center">
        <h2 class="text-xl font-bold text-gray-900 whitespace-nowrap">
          🎓 Select the correct image of you! 🎓
        </h2>
        <p class="mt-2 text-gray-700">
          Confirm yourself from the gallery before picking the perfect moment.
        </p>
      </div>
      <div class="w-full px-4">
        <!-- Images Row -->
        <div class="flex flex-wrap mb-4">
          <div
            v-for="(image, index) in images.slice(startIndex, endIndex + 1)"
            :key="image.id"
            :class="getImageClass(index)"
            @click="submit(index + startIndex)"
          >
            <img
              :src="image.src"
              :alt="image.alt"
              class="w-auto m-auto h-auto object-cover rounded-md"
              :style="getImageStyle(index)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w-full px-4 py-4 flex justify-between">
      <button
        v-if="startIndex > 0"
        class="btn"
        type="button"
        @click="previousPage"
      >
        Previous Page
      </button>
      <div v-else class="py-2 px-4"></div>
      <!-- Invisible spacer for left button -->

      
        <button v-if="!showingPreviousNext" class="btn" type="button" @click="snapshotNotMe">
          I can't see myself
        </button>
       
     
      <!-- Spacer to push buttons to the sides -->
      <button
        v-if="
          images.length + ((images.length % numImages) - 1) >=
          endIndex + numImages - 1
        "
        class="btn"
        type="button"
        @click="nextPage"
      >
        Next Page
      </button>
      <div v-else class="py-2 px-4"></div>
      <!-- Invisible spacer for right button -->
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "SnapshotImageSelector",
  components: {},
  props: { name: String, images : Array },
  data() {
    return {
      numImages: 6,
      startIndex: 0,
      endIndex: 5,
      showingPreviousNext: false,
    };
  },
  methods: {
    nextPage() {
      this.startIndex = this.endIndex + 1;
      this.endIndex = this.startIndex + this.numImages - 1;
    },
    previousPage() {
      var old_start = this.startIndex;
      this.startIndex -= this.numImages;
      this.endIndex = old_start - 1;
    },
    snapshotNotMe(){
      this.showingPreviousNext = true;
      this.$emit("notMe")
    },
    submit(index) {
      console.log(index);
      this.$emit("submit", index);
    },
    getImageClass(index) {
      // Adjust the width of the images based on their index and screen size
      if (index === 0) {
        // The first image is full width on mobile and one-third on desktop
        return "w-full md:w-1/3 p-2 max-height-70vh";
      }
      // The second image is half-width on mobile and one-third on desktop
      else if (index === 1) {
        return "w-1/2 md:w-1/3 p-2";
      }
      // The third image is half-width on mobile, hidden on desktop
      // This is to maintain the two images in the second row on mobile
      else if (index === 2) {
        return "w-1/2 md:w-1/3 p-2";
      } else {
        // All other images are one-half on mobile and one-third on desktop
        return "w-1/3 md:w-1/3 p-2";
      }
    },
    getImageStyle() {
      // Limit the height of the first image on desktop
      //if (index === 0) return 'max-height: 60vh;';
      return "";
    },
  },
};
</script>
  <style scoped>
.image-carousel-container {
  max-width: 85vw;
  width: 100%;
  margin: auto;
}
.slick-prev:before {
  content: "";
}
.slick-next:before {
  content: "";
}

.preview-container {
  text-align: center;
  margin-bottom: 20px;
}

.preview-image {
  height: auto;
  max-height: 80vh;
  border: 3px solid #ddd;
  border-radius: 8px;
}

.thumbnail img {
  max-height: 20vh;
  padding: 5px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.1s ease;
}

.thumbnail img:hover,
.thumbnail img:focus {
  opacity: 1;
}

.preview {
  max-width: 90vw;
}
.preview img {
  max-height: 70vh;
  margin-left: auto;
  margin-right: auto;
  display: block;

  padding: 5px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.1s ease;
}

.preview img:hover,
.preview img:focus {
  opacity: 1;
}
/* Style the button and place it in the middle of the container/image */
.btn {
  background-color: #08697b;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.image-carousel-container .btn:hover {
  background-color: black;
}
</style>