<template>
  <div class="md:w-1/2 sm:w-full mx-auto">
    <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
      <div class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
        <MyGraduationClipSuccessMessage
          v-if="state == 'clip'"
          :email="email"
        />

        <SnapshotSuccessMessage
          v-if="state == 'snapshot'"
          :email="email"
          :nanoID="nanoID"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { endSession, trackEvent } from '../../Analytics.vue';
import SnapshotSuccessMessage from './StoreSuccessMessage/SnapshotSuccessMessage.vue'
import MyGraduationClipSuccessMessage from './StoreSuccessMessage/MyGraduationClipSuccessMessage.vue'
export default {
  name: "StoreSuccess",
  components: {
    SnapshotSuccessMessage,
    MyGraduationClipSuccessMessage
  },
  data() {
    return {
      email: this.$route.query.email,
      state: 'clip',
      nanoID: ''
    };
  },
  mounted() {
    trackEvent(this.$route.path, {label: "PURCHASE_SUCCESS", paid: true, email: this.email})
    endSession()
    this.checkForNanoID()
  },
  methods: {
    checkForNanoID(){
      var id = this.$route.query.nanoID;
      if(id){
        this.state = 'snapshot'
        this.nanoID = id
      }
    }
  },
};
</script>
