<template>
  <div class="md:w-1/2 sm:w-full mx-auto">
    <div class="flex flex-col flex-wrap -mx-2 space-y-4 md:space-y-0">
      <div class="mt-8 mx-auto max-w-7xl">
        <div class="text-center w-full h-auto">
          <h1
            class="
              text-4xl
              tracking-tight
              font-extrabold
              text-gray-900
              sm:text-5xl
              md:text-6xl
            "
          >
            <span class="block">{{ firstname }}, let's create</span>
            <span class="block text-mygrad">your GraduationClip</span>
          </h1>
          <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-500
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            Start by <b>finding yourself</b> in the ceremony.
          </p>
          <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-500
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            Once you can see yourself on screen and taken your first few steps, <br /><b>pause the video</b>
            and press continue.
          </p>
        </div>
      </div>

      <div class="mt-8 mx-auto max-w-7xl">
        <div class="text-center w-full">
          <video-player style="height: auto !important; max-height: 100% !important;"
            :options="videoOptions"
            ref="videoPlayer"
            @statechanged="playerStateChanged"
            @loadeddata="onPlayerReady"
          ></video-player>
        </div>
      </div>
      <div
        
        class="mt-8 mx-auto py-3 max-w-7xl w-full"
      >
        <div class="text-center w-full h-auto">
          <button
            @click="takeMeBack"
            type="submit"
            class="
              inline-flex
              items-center
              px-4
              py-2
              border border-gray-300
              shadow-sm
              text-lg
              font-medium
              rounded-md
              text-gray-700
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            Take me back 
          </button>
        </div>
        </div>
      <div
        v-if="focalPoint !== null"
        class="mt-8 mx-auto py-3 max-w-7xl w-full"
      >
        <div class="text-center w-full h-auto">
          <button
            @click="submit"
            type="submit"
            class="
              inline-flex
              items-center
              px-4
              py-2
              border border-gray-300
              shadow-sm
              text-lg
              font-medium
              rounded-md
              text-gray-700
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            Continue
          </button>
        </div>
      </div>
      <div class="mt-8 mx-auto max-w-7xl">
        <div class="text-center w-full h-auto">
          <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-500
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            {{ this.ceremony.datetime | formatDate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import {trackEvent} from "../../Analytics.vue"

export default {
  name: "FindInitialFocalPoint",
  props: {
    firstname: String,
    ceremony: Object,
  },
  data: function () {
    return {
      video: this.ceremony.video,
      videoOptions: {
        sources: [{
          type: "application/x-mpegURL",
          src: this.ceremony.video,
        }],
        aspectRation: "16:9",
        responsive: true,
        controlBar: {
          durationDisplay: false,
          remainingTimeDisplay: false,
        }
      }, 
      focalPoint: null,
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    takeMeBack() {
      if (this.$route.query.timecode) {
        this.player.currentTime(this.$route.query.timecode - 5);
        this.player.pause()
      }
      else if (this.focalPoint !== null){
        this.player.currentTime(this.focalPoint - 15); 
      }
    },
    submit() {
      this.$emit("submit", this.focalPoint);
    },
    onPlayerReady() {
      console.log("Player Ready");
      if (this.$route.query.timecode) {
        this.player.currentTime(this.$route.query.timecode);
      }
    },
    onPlayerPlay(player) {
      console.log("player play!", player);
    },
    onPlayerPause(player) {
      console.log("player pause!", player);
      console.log("Current time: ", player.currentTime());
      this.focalPoint = Math.ceil(player.currentTime() * 25) / 25; // Multiple by frames, ceil, then div
      trackEvent(this.$route.path, {label: 'MANUAL_SUBMISSION_FOCAL_POINT_CHANGE', registration_id: this.$route.params.id, time: this.focalPoint})
    },
    playerStateChanged(event) {
      if ("loadeddata" in event && event.loadeddata) {
        this.onPlayerReady(this.player);
      }
      if ("pause" in event && event.pause) {
        this.onPlayerPause(this.player);
      } else if ("play" in event && event.play) {
        this.onPlayerPlay(this.player);
      }
      console.log("change", event);
    },
  },
  components: {
  
  },
};
</script>
<style scoped>
video {
  min-width: 100% !important;
  max-height: 100%;
  position: relative !important;
}
.video-js {
  width: 100% !important;
  position: relative !important;
  height: unset;
}
.video-player {
  min-width: 100% !important;
  height: auto;
}

</style>