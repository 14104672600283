import { render, staticRenderFns } from "./SnapshotGallery.vue?vue&type=template&id=897db57c&scoped=true"
import script from "./SnapshotGallery.vue?vue&type=script&lang=js"
export * from "./SnapshotGallery.vue?vue&type=script&lang=js"
import style0 from "./SnapshotGallery.vue?vue&type=style&index=0&id=897db57c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "897db57c",
  null
  
)

export default component.exports