<template>
  <div>
    <div class="relative lg:w-3/4 md:w-5/6 sm:w-5/6 mx-auto">

      <!-- Cart button top right -->
      <div class="cart-container">
        <button class="cart-btn" @click="openCartModal">
          <div class="relative">
            <img
              :src="require('@/assets/cart.svg')"
              alt="Cart"
              class="cart-icon"
            />
            <span class="absolute -bottom-2 -right-2 cart-counter">
              {{ selectedImages.length }}
            </span>
          </div>
        </button>
      </div>

      <!-- Header -->
      <div class="text-center mt-4 mx-auto max-w-7xl px-4 sm:mt-2 sm:px-6">
        <h2 class="text-2xl tracking-tight font-extrabold text-gray-900">
          {{ name }}
        </h2>
        <h2 class="text-xl font-bold text-gray-900 whitespace-nowrap">
          🎓 Your moment, your memory. 🎓
        </h2>
        <p class="mt-2 text-gray-700">
          Choose the Snapshot(s) that brings your achievement to life.
        </p>
        <p class="mt-2 text-gray-700">
          You can choose as many as you like!
        </p>
      </div>

      <!-- Grid/Carousel View -->
      <div v-if="!showCarousel">
        <preview-grid
          :images="images"
          :selectedImages="selectedImages"
          @select-image="showCarouselAtIndex"
        />
      </div>
      <div v-else>
        <preview-carousel
          :images="images"
          :selectedImages="selectedImages"
          :settings="carouselSettings"
          :initial-slide="currentImageIndex"
          @add-to-cart="handleAddToCart"
          @back-to-grid="showCarousel = false"
          @view-cart="openCartModal"
        />
      </div>
    </div>

    <!-- Cart Modal -->
    <cart-modal
      :is-open="isCartModalOpen"
      :items="selectedImages"
      @remove="removeItem"
      @close="closeCartModal"
      @submit="goToCheckout"
    />
    <div class="text-center mt-4 mx-auto max-w-7xl px-4 sm:mt-2 sm:px-6">
        <p class="text-xl font-bold text-gray-900">
          Note: Award Photo Prints are only available in person on the day of your ceremony. 
          </p><p>Please look for signage of where to go to pay and collect.
          You must select your images here prior to printing.
        </p>
       
      </div>

  </div>
</template>

<script>
import PreviewCarousel from "./PreviewCarousel.vue";
import CartModal from "./CartModal.vue";
import PreviewGrid from "./PreviewGrid.vue";

export default {
  name: "SnapshotImageSelector",
  components: {
    PreviewCarousel,
    CartModal,
    PreviewGrid,
  },
  props: { 
    name: String, 
    images: Array 
  },
  data() {
    return {
      selectedImages: [],
      isCartModalOpen: false,
      carouselSettings: {
        focusOnSelect: true,
        infinite: true,
        speed: 50,
        waitForAnimate: false,
        centerMode: true,
        lazyLoad: 'ondemand',
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        swipe: true,
        draggable: true,
        dots: false,
        arrows: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              focusOnSelect: true,
              infinite: true,
              centerMode: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              swipe: true,
              draggable: true,
              dots: false,
              arrows: true,
            },
          },
        ],
      },
      showCarousel: false,
      currentImageIndex: 0,
    };
  },
  methods: {
    handleAddToCart(imageIndex) {
      // Grab the image from 'images' array by index
      const image = this.images[imageIndex];
      var printOnly = false;
      if (typeof this.$route.query.printOnly === undefined) {
        printOnly = false;
      }
      else {
        if (this.$route.query.printOnly == "true") {
          printOnly = true
        }
        else {
          printOnly = false
        }
      }
      // If it’s not already in selectedImages, push it
      if (!this.selectedImages.includes(image)) {
        // Initialize isDigitalSelected, isPrintSelected, etc.
        if (printOnly){
          this.$set(image, "isPrintSelected", true);
          this.$set(image, "isDigitalSelected", false);
          this.$set(image, "printQuantity", 1);

        }
        else {
          // Only allow digital
          this.$set(image, "isDigitalSelected", true);
          this.$set(image, "isPrintSelected", false);
        }
       
        this.selectedImages.push(image);
      } else {
        // Or remove if you'd like toggling behavior
        // this.selectedImages.splice(this.selectedImages.indexOf(image), 1);
      }
    },
    goToCheckout(items) {
      console.log("Going to checkout")
      this.$emit("checkout", items)
    },
    openCartModal() {
      this.isCartModalOpen = true;
    },
    closeCartModal() {
      this.isCartModalOpen = false;
    },
    removeItem(image) {
      const idx = this.selectedImages.indexOf(image);
      if (idx !== -1) {
        this.selectedImages.splice(idx, 1);
      }
      if (this.selectedImages.length === 0) {
        this.closeCartModal();
      }
    },
    showCarouselAtIndex(index) {
      console.log("SHowing Carousel")
      console.log(index)
      this.currentImageIndex = index;
      this.showCarousel = true;
    },
  },
};
</script>

<style scoped>


.cart-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.cart-btn {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 8px;
}

.cart-icon {
  width: 3em;
  height: 3em;
}

.cart-counter {
  position: absolute;
  bottom: -8px;
  right: -8px;
  background-color: #08697b;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  min-width: 20px;
  text-align: center;
}

/* Remove the old selected-images styles */
.selected-images {
  display: none;
}


</style>