<template>
    <div v-if="isOpen" class="cart-modal-overlay">
      <div class="cart-modal">
        <button class="close-btn" @click="closeModal">×</button>
  
        <div class="modal-content">
          <div class="cart-details">
            <h2>{{ items.length }} items in your cart</h2>
            <div class="bg-green-100 p-4 rounded-lg mb-4 w-full">
              <p class="pr-6 w-full" v-if="showDigitalInfo">
              <span class="font-bold">Digital Images:</span><br/>
              • <span class="font-semibold">1st image:</span> £20<br/>
              • <span class="font-semibold">2nd image + additional:</span> £15 each<br/>
              </p>
              <p class="pr-6 w-full" v-else>
              <span class="font-bold">Print Images:</span><br/>
              • <span class="font-semibold">From:</span> £15 each<br/>
              (Various frames available)<br/>
              </p>
              
            </div>
                      
            <div class="cart-items pb-32 md:pb-24">
              <div
                v-for="image in items"
                :key="image.id"
                class="cart-item"
              >
                <div class="flex flex-col items-center">
                  <img :src="image.src" class="item-thumbnail" />
                  <button
                  @click="removeItem(image)"
                  class="bg-red-500 hover:bg-red-700 text-white text-sm font-bold py-2 px-4 rounded mt-2"
                  >
                  Remove
                  </button>
                </div>
                <div class="item-info">
                  <!--

                  <div class="purchase-option py-1 ">
                    <label>
                      <input
                        type="checkbox"
                        v-model="image.isDigitalSelected"
                      />
                      Digital Image
                    </label>
                  </div>
  

                  <div class="purchase-option py-1">
                    <label>
                      <input
                        type="checkbox"
                        v-model="image.isPrintSelected"
                      />
                      Print
                    </label>
                    
                  </div>


                   <div class="purchase-option" v-if="image.isDigitalSelected !=true && image.isPrintSelected != true">
                    <p class="text-red-500 text-sm">Please select how you'd like to receive this photo.</p>
                   </div>
  
                  -->
                
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Sticky footer for total & payment button -->
        <div class="sticky-footer">
          <h3 v-if="digitalSelected">Total: £{{ cartTotal }}</h3>
          <button
            class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            @click="purchaseButtonClicked"
          >
            <span v-if="isOnlyPrints">Submit selection and pay in person</span><span v-else>Pay Online for Digital</span>
          </button>
          <p class="mt-2 text-sm text-gray-600">
            Note: Print purchases can be made on graduation day at our photo desk. (You will receieve a reference code of all images selected here later.)
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CartModal",
    props: {
      isOpen: { type: Boolean, default: false },
      items: { type: Array, default: () => [] },
    },
    computed: {
      showDigitalInfo() {
        if (typeof this.$route.query.printOnly === undefined) {
          return true;
        }
        else {
          if (this.$route.query.printOnly == "true") {
            return false;
          }
          else {
            return true;
          }
        } 
      },
      isOnlyPrints() {
        return this.items.every(item => item.isPrintSelected && !item.isDigitalSelected)
      },
      digitalSelected() {
        return this.items.some(item => item.isDigitalSelected)
      },
      cartTotal() {
        var digitals_selected = 0
        // For each image, add cost if digital is selected
        // (first digital=£20, subsequent=£15, etc.)
        this.items.forEach((img) => {
          if (img.isDigitalSelected) {
            digitals_selected += 1; 
          }
        });
        if (digitals_selected == 0) {
          return 0;
        }
        return ((digitals_selected - 1) * 15) + (20);
      },
    },
    methods: {
      closeModal() {
        this.$emit("close");
      },
      removeItem(image) {
        this.$emit("remove", image);
      },
      checkItemsBeforeEmmitting(items) {
        let errors = 0;
        items.forEach((item) => {
          // Check if delivery method have been selected
          if(item.isDigitalSelected == false && item.isPrintSelected == false){
            errors++
          }
        });
        return errors
      },      
      purchaseButtonClicked() {
        console.log("Purchase Button Clicked")
        let errorsCaught = this.checkItemsBeforeEmmitting(this.items);
        if(errorsCaught >= 1){
          console.log(`Delivery method not selected for: ${errorsCaught} items`);
          return
        }
        this.$emit("submit", this.items) 
      },
    },
  };
  </script>
  
  <style scoped>
  /* All your modal styles go here. */
  .cart-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.cart-modal {
  background: white;
  width: 95vw;
  height: 90vh;
  border-radius: 8px;
  position: relative;
}


.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  font-size: 32px;
  cursor: pointer;
  z-index: 2001;
}

.modal-content {
  display: flex;
  height: 100%;
}

.cart-details {
  padding: 20px;
  width: 100%;
  border-left: 1px solid #eee;
  overflow-y: auto;
}

.cart-item {
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.item-thumbnail {
  height: 300px;
  object-fit: scale-down;
  margin-right: 15px;
}


.sticky-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: white; /* or match your page background */
  padding: 1rem;
  text-align: center;
  border-top: 1px solid #ddd;
  margin-bottom: 0 !important;
  /* you could do a drop shadow or highlight to make it stand out */
}
  </style>
  